import { graphql } from 'gatsby';
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

export interface TermsAndConditionsProps {
  data: {
    header: {
      childImageSharp: {
        fluid: any;
      };
    };
  };
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = props => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header
        css={[outer, SiteHeader]}
        style={{
          backgroundImage: `url('${props.data.header.childImageSharp.fluid.src}')`,
        }}
      >
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>Terms and conditions</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Conjurer Solutions Limited&apos;s relationship with you in relation to this website.</p>
              <p>The term &apos;Conjurer Solutions Limited&apos; or &apos;us&apos; or &apos;we&apos; refers to the owner of the website whose registered office is 14 Portman Crescent, Bournemouth, BH5 2ER. Our company registration number is 06284919. The term &apos;you&apos; refers to the user or viewer of our website.</p>
              <p>If you disagree with any part of these terms and conditions, please do not use our website.</p>
              <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
              <p>This website uses cookies to monitor browsing preferences.</p>
              <p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
              <p>Services or information available through this website meet your specific requirements.</p>
              <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>
              <p>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</p>
              <p>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.</p>
              <p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
              <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Northern Ireland, Scotland and Wales.</p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default TermsAndConditions;

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "img/BLACK_I_desktop-1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

